<template>
  <button
    class="tabButton"
    :class="{
      'tabButton--active': isSelected,
      'tabButton--disabled': isDisabled
    }"
    :title="title"
    @click.stop.prevent="$emit('select-tab')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: ['select-tab']
}
</script>

<style lang="scss">
.tabButton {
  height: 38px;
  width: 100%;
  padding: 0 rem(24);
  background-color: #fff;
  border: 0;
  transition: 0.2s linear;
  font-family: $mb-font-text;
  font-size: rem(16);
  line-height: 1.13;
  letter-spacing: normal;
  color: $grey--darkest;
  cursor: pointer;
  position: relative;
  white-space: nowrap;

  &--disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: #c0c0c0;
    background-color: #ffffff;
  }

  &::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    display: block;
    margin: auto;
    height: 2px;
    width: 0;
    background-color: transparent;
    transition:
      width 0.3s ease,
      background-color 0.3s ease;
  }

  .amgIcon {
    height: 10px;
    width: auto;
    color: $grey--light;
  }

  .logoIcon {
    height: 24px;
    width: 24px;
    color: $grey--light;
  }

  .smartIcon {
    height: 24px;
    color: $grey--light;
  }

  .EQIcon {
    color: $grey--light;
    height: 12px;
  }
}

.tabButton--active {
  pointer-events: none;

  &::before {
    width: 100%;
    background-color: $blue;
  }

  .amgIcon,
  .smartIcon,
  .logoIcon,
  .EQIcon {
    transition: color 0.3s;
    color: $grey--darkest;
  }
}

.tabButton__icon {
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: center;
}
</style>
